exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-archive-jsx": () => import("./../../../src/pages/archive.jsx" /* webpackChunkName: "component---src-pages-archive-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-success-jsx": () => import("./../../../src/pages/success.jsx" /* webpackChunkName: "component---src-pages-success-jsx" */),
  "component---src-pages-tags-jsx": () => import("./../../../src/pages/tags.jsx" /* webpackChunkName: "component---src-pages-tags-jsx" */),
  "component---src-templates-project-jsx": () => import("./../../../src/templates/project.jsx" /* webpackChunkName: "component---src-templates-project-jsx" */),
  "component---src-templates-tag-jsx": () => import("./../../../src/templates/tag.jsx" /* webpackChunkName: "component---src-templates-tag-jsx" */)
}

